import React from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { ITOGCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import './employee.scss';
import './tog-character-dynamic.scss';
import { SectionHeader } from '../modules/common/components/section-header';
import { Alert, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import ScrollspyNav from 'react-scrollspy-nav';
import { RatingBox } from '../modules/common/components/rating-box';
import { TOGCharacter } from '../modules/tog/common/components/tog-character';
import { TOGRarity } from '../modules/tog/common/components/tog-rarity';
import { TOGClass } from '../modules/tog/common/components/tog-class';
import { TOGColor } from '../modules/tog/common/components/tog-color';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface ITogCharacterNodes {
  nodes: ITOGCharacter[];
}

interface ITogCharacterEntry {
  currentUnit: ITogCharacterNodes;
}

interface IProps {
  data: ITogCharacterEntry;
}

const TogCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <DashboardLayout
      className={'generic-page character-page character-tog '}
      game="tog"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        <TOGCharacter mode="icon" slug={character.slug} />
        <div className="character-details">
          <h1>
            {character.name}{' '}
            {character.isNew && <span className="tag new">NEW!</span>}{' '}
            {character.isUpdated && <span className="tag buffed">Updated</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <TOGRarity rarity={character.rarity} />
            <TOGClass classChar={character.class} />
            <TOGColor classChar={character.color} />
          </div>
        </div>
        <div className="character-ratings">
          {character.ratings.low != '1' ? (
            <>
              <RatingBox
                game="nikke"
                rating={character.ratings.low}
                ratingName="ADV (Rev 3)"
              />
              <RatingBox
                game="nikke"
                rating={character.ratings.high}
                ratingName="ADV (Rev 6)"
              />
            </>
          ) : (
            <p className="no-ratings">Ratings are pending review.</p>
          )}
        </div>
      </div>
      <div className="floating-nav">
        <ScrollspyNav
          scrollTargetIds={[
            'section-profile',
            'section-skills',
            'section-review',
            'section-gallery'
          ]}
          activeNavClass="is-active"
          scrollDuration="500"
          headerBackground="true"
        >
          <ul className={`list-unstyled ${character.color}`}>
            <li>
              <a href="#section-profile">Profile</a>
            </li>
            <li>
              <a href="#section-skills">Skills</a>
            </li>
            <li>
              <a href="#section-review">Review</a>
            </li>
            <li>
              <a href="#section-gallery">Gallery</a>
            </li>
          </ul>
          <div className="mobile-arrow">
            <FontAwesomeIcon
              icon={faHandPointRight}
              className="arrow"
              width="18"
            />
          </div>
        </ScrollspyNav>
      </div>
      <div id="section-profile" className="id-section">
        <SectionHeader title="Profile" />
        <Row xs={1} xl={2} xxl={3} className="info-list">
          <Col>
            <h5>Personal information</h5>
            <div className="info-list-row">
              <div className="category">Name</div>
              <div className="details">{character.name}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Title</div>
              <div className="details">
                {character.title ? character.title : '-'}
              </div>
            </div>
          </Col>
          <Col>
            <h5>Combat information</h5>
            <div className="info-list-row">
              <div className="category">Rarity</div>
              <div className="details">{character.rarity}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Color</div>
              <div className="details">{character.color}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Class</div>
              <div className="details">{character.class}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Type</div>
              <div className="details">{character.type}</div>
            </div>
          </Col>
          <Col>
            <h5>Release information</h5>
            <div className="info-list-row ">
              <div className="category">Release date</div>
              <div className="details">
                {character.releaseDate ? character.releaseDate : '-'}
              </div>
            </div>
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </div>
      <div id="section-skills" className="id-section">
        <SectionHeader title="Skills" subtitle="At Lv. 5" />
        {character.skills ? (
          <>
            <Row className="skills">
              {character.skills.map((skill, index) => {
                return (
                  <Col xs={12} xxl={6} key={index}>
                    <div className="skill-box bcm">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.color}`}>
                          <FontAwesomeIcon width="36" icon={faCube} />
                        </div>
                        <div className="name-section">
                          <h5 className="name">{skill.name}</h5>
                          <div className="pills">
                            <span className="skill-type pill">
                              {skill.type}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="skill-content">
                        {skill.description && (
                          <>{renderRichText(skill.description, options)}</>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>Skills are not available for this character yet.</strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
        <SectionHeader title="Exclusive Equipment" subtitle="At Lv. 4" />
        {character.exclusiveEq ? (
          <>
            <Row className="skills">
              <Col xs={12} xxl={12}>
                <div className="skill-box bcm">
                  <div className="skill-header">
                    <div className={`skill-icon ${character.color}`}>
                      <FontAwesomeIcon width="36" icon={faCube} />
                    </div>
                    <div className="name-section">
                      <h5 className="name">{character.exclusiveEq.name}</h5>
                      <div className="pills">
                        <span className="skill-type pill">
                          HP: {character.exclusiveEq.hp}%
                        </span>
                        <span className="skill-type pill">
                          ATK: {character.exclusiveEq.atk}%
                        </span>
                        <span className="skill-type pill">
                          DEF: {character.exclusiveEq.def}%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="skill-content">
                    <ul>
                      <li>
                        <span className="level">Lv. 1</span>{' '}
                        {character.exclusiveEq.level1
                          ? character.exclusiveEq.level1
                          : '-'}
                      </li>
                      <li>
                        <span className="level">Lv. 2</span>{' '}
                        {character.exclusiveEq.level2
                          ? character.exclusiveEq.level2
                          : '-'}
                      </li>
                      <li>
                        <span className="level">Lv. 3</span>{' '}
                        {character.exclusiveEq.level3
                          ? character.exclusiveEq.level3
                          : '-'}
                      </li>
                      <li>
                        <span className="level">Lv. 4</span>{' '}
                        {character.exclusiveEq.level4
                          ? character.exclusiveEq.level4
                          : '-'}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>
                Exclusive Equipment information are not available for this
                character yet.
              </strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-review" className="id-section">
        <SectionHeader title="Review" />
        {!character.isReviewPending ? (
          <div className="section-analysis">
            <div className="analysis">
              <h5 className="analysis-header">Review</h5>
              {character.review ? (
                <div>
                  <div className={`review raw`}>
                    {character.review ? (
                      <>{renderRichText(character.review, options)}</>
                    ) : (
                      <p>Review is pending.</p>
                    )}
                  </div>
                  <h5 className="analysis-header">Pros &amp; Cons</h5>
                  {character.pros && character.cons ? (
                    <Row className="pros-cons">
                      <Col sm="12" md="6">
                        <div className="box pros">
                          <h5>Pros</h5>
                          <hr />
                          <div className="raw list">
                            {renderRichText(character.pros, options)}
                          </div>
                        </div>
                      </Col>
                      <Col sm="12" md="6">
                        <div className="box cons">
                          <h5>Cons</h5>
                          <hr />
                          <div className="raw list">
                            {renderRichText(character.cons, options)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Alert variant="primary">
                      <Alert.Heading>
                        No pros & cons are available for this character yet
                      </Alert.Heading>
                      <p>We will add them as soon as it is possible!</p>
                    </Alert>
                  )}
                </div>
              ) : (
                <Alert variant="primary">
                  <Alert.Heading>
                    No review is available for this character yet
                  </Alert.Heading>
                  <p>We will add it as soon as it is possible!</p>
                </Alert>
              )}
            </div>
          </div>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>Review is not available for this character yet.</strong>{' '}
              We will add it as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-gallery" className="id-section">
        <SectionHeader title="Gallery" />
        <Alert variant="primary">
          <Alert.Heading>
            No full image is not available for this character yet
          </Alert.Heading>
          <p>We will add it as soon as it is possible!</p>
        </Alert>
      </div>
    </DashboardLayout>
  );
};

export default TogCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Tower of God: New World | Prydwen Institute'}
      description={
        'Discover the information and guides for ' +
        character.name +
        ' in Tower of God: New World.'
      }
      image={character.imageSmall}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulTogCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...TogCharacterFieldsFragment
      }
    }
  }
`;
